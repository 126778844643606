<template>
  <div>
    <v-row>
      <v-col v-if="!inCompetence" md="4">
        <v-select
          v-model="compId"
          label="Компетенция"
          :items="competenceOptions"
        ></v-select>
      </v-col>
      <v-col md="2" sm="4" xs="6">
        <v-select v-model="year" label="Год" :items="yearsOptions"></v-select>
      </v-col>
      <v-col md="4">
        <validation-provider
          v-slot="{ errors }"
          ref="searchStr"
          rules="id_or_email"
        >
          <v-text-field
            v-model="searchUser"
            label="Пользователь"
            hint="Укажите Talent ID или Email"
            persistent-hint
            clearable
            :error-messages="errors"
            @click:clear="handleResetSearchUser"
          >
          </v-text-field>
        </validation-provider>
      </v-col>
      <v-col md="4">
        <v-checkbox
          v-model="hideZeros"
          label="Скрывать нулевые результаты"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { email, integer } from "vee-validate/dist/rules";
import debounce from "lodash/debounce";
import { getScoreYears } from "@/services/scores";
extend("id_or_email", {
  validate(value) {
    const isValidInt = integer.validate(value);
    const isValidEmail = email.validate(value);
    return isValidInt || isValidEmail;
  },
  message() {
    return "Укажите id или валидный Email";
  },
});
export default {
  name: "ScoresFilter",
  props: {
    /**
     * если компонент используется в контексте
     * конкретной компетенции, то запрещаем менять компетенцию
     */
    inCompetence: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      searchUser: null,
    };
  },
  computed: {
    compId: {
      get() {
        return this.filters.competence_id;
      },
      set(value) {
        this.$emit("changeFilters", { competence_id: value });
      },
    },
    year: {
      get() {
        return this.filters.year;
      },
      set(value) {
        this.$emit("changeFilters", { year: value });
      },
    },
    hideZeros: {
      get() {
        return this.filters.hide_zeros;
      },
      set(value) {
        this.$emit("changeFilters", { hide_zeros: value });
      },
    },
    competenceOptions() {
      let result = [
        {
          text: "Все компетенции",
          value: null,
        },
      ];
      const competences = this.$store.state.competences?.map((competence) => {
        return {
          text: competence.name,
          value: competence.id,
        };
      });
      result = result.concat(competences);
      return result;
    },
    // Опции года, берем от 2018, наверное
    yearsOptions() {
      const years = getScoreYears();
      const result = [
        {
          text: "Все годы",
          value: null,
        },
      ];
      years.forEach((n) => {
        result.push({
          text: n,
          value: n,
        });
      });
      return result;
    },
  },
  watch: {
    searchUser: {
      handler(val) {
        if (!val) {
          this.handleResetSearchUser();
        } else {
          this.debouncedSearch();
        }
      },
    },
  },
  created() {
    this.debouncedSearch = debounce(this.handleSetSearchUser, 400);
    if (this.$route.query?.search) {
      this.searchUser = this.$route.query?.search;
    }
  },
  methods: {
    handleResetSearchUser() {
      this.$emit("changeFilters", { user_id: null, search: "" });
      this.$refs.searchStr.reset();
    },
    async handleSetSearchUser() {
      if (!this.searchUser) {
        this.handleResetSearchUser();
      }
      const isValid = await this.$refs.searchStr.validate(this.searchUser);
      if (isValid?.valid) {
        const isInt = integer.validate(this.searchUser);
        console.log("isInt", isInt);
        if (isInt) {
          this.$emit("changeFilters", { user_id: this.searchUser, search: "" });
        } else {
          this.$emit("changeFilters", {
            user_id: null,
            search: this.searchUser,
          });
        }
      }
    },
  },
};
</script>

<style></style>
