<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <v-card-title class="c-title"
        >Детализация баллов пользователя #{{ score && score.user_id }} по
        компетенции &laquo;{{
          competence && competence.name
        }}&raquo;</v-card-title
      >
      <v-card-text class="black--text">
        <div v-if="stepikDitails">
          <div class="text-h6">Детализация по Stepik</div>
          <v-simple-table class="mb-3">
            <template #default>
              <tbody>
                <tr>
                  <td>Балл за Stepik</td>
                  <td class="text-right">
                    {{ stepikDitails.score }}
                  </td>
                </tr>
                <tr>
                  <td>Количество курсов (тестирование)</td>
                  <td class="text-right">
                    {{ stepikDitails.courses }}
                  </td>
                </tr>
                <tr>
                  <td>Сертификаты с отличием</td>
                  <td class="text-right">
                    {{ stepikDitails.certs_distinction }}
                  </td>
                </tr>
                <tr>
                  <td>Обычные сертификаты</td>
                  <td class="text-right">
                    {{ stepikDitails.certs_regular }}
                  </td>
                </tr>
                <tr v-if="stepikDitails.courseList.length">
                  <td>
                    Все курсы по пользователю ({{
                      stepikDitails.courseList.length
                    }})
                  </td>
                  <td class="text-right">
                    <template v-for="(c, i) in stepikDitails.courseList">
                      <a :key="c.id" :href="c.url" target="stepik">{{ c.id }}</a
                      >{{
                        i === stepikDitails.courseList.length - 1 ? "" : ","
                      }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-if="error">
          <p>Не удалось получить данные о детальной информации по участнику.</p>
          <p class="error--text">{{ error }}</p>
        </div>
        <div v-else>
          <div class="text-h6">Информация по достижениям</div>
          <v-simple-table class="mb-3">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">Статус</th>
                  <th class="text-right">Количество достижений</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in statsList" :key="item.title">
                  <td>{{ item.title }}</td>
                  <td class="text-right">{{ item.count }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
      <v-card-actions class="px-5 pb-4">
        <v-btn color="primary" outlined @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DEFAULT_DIALOG_WIDTH } from "@/constants";
import { STATUS_TEXTS } from "@/services/achievements";
import { apiClient } from "@/api";
export default {
  name: "ScoreDetailsModal",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: DEFAULT_DIALOG_WIDTH,
    },
    /**
     * @type Score:{ user_id: number, competence_id: number, year?:number }
     */
    score: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pending: false,
      error: "",
      stats: null,
    };
  },
  computed: {
    competence() {
      const id = this.score?.competence_id;
      if (!id) return;
      return this.$store.state.competences.find((n) => n.id === id);
    },
    statsList() {
      const { stats } = this;
      return Object.entries(STATUS_TEXTS).reduce((acc, [status, title]) => {
        acc.push({
          title,
          status,
          count: stats?.count?.[status] || 0,
        });
        return acc;
      }, []);
    },
    stepikDitails() {
      const details =
        this.score?.details?.stepik > 0 && this.score?.all_details?.stepik;
      if (!details) return;
      const courseList = details?.courses_ids
        ?.sort((a, b) => {
          return Number(a) - Number(b);
        })
        .map((n) => {
          return {
            id: n,
            url: `https://stepik.org/course/${n}`,
          };
        });
      return {
        ...details,
        courseList: courseList || [],
      };
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.init();
        } else {
          this.reset();
        }
      },
    },
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
    },
    async init() {
      const { score } = this;
      if (!score) return;
      this.pending = true;
      try {
        const { data } = await apiClient({
          method: "GET",
          url: "/achievements/stats",
          params: {
            user_id: score.user_id,
            ...(score.competence_id && { competence_id: score.competence_id }),
          },
        });
        if (Array.isArray(data)) {
          this.stats = data[0];
        }
      } catch (error) {
        this.stats = null;
        this.error = error.message;
      }
      this.pending = false;
    },
    reset() {
      this.stats = null;
      this.error = "";
      this.pending = false;
    },
  },
};
</script>

<style scoped>
.c-title {
  word-break: normal;
}
</style>
