import {
  ACHIEVEMENT_NO_STATUS,
  ACHIEVEMENT_STATUS_APPROVED,
  ACHIEVEMENT_STATUS_MODERATION,
  ACHIEVEMENT_STATUS_REJECTED,
  ACHIEVEMENT_STATUS_USER_REJECTED,
} from "@/constants";

export const STATUS_TEXTS = {
  [ACHIEVEMENT_STATUS_APPROVED]: "Подтверждено",
  [ACHIEVEMENT_NO_STATUS]: "Не рассмотрено пользователем",
  [ACHIEVEMENT_STATUS_MODERATION]: "На модерации",
  [ACHIEVEMENT_STATUS_REJECTED]: "Отклонено",
  [ACHIEVEMENT_STATUS_USER_REJECTED]: "Отклонено пользователем",
};
