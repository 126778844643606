<template>
  <div>
    <v-simple-table class="mb-3">
      <template #default>
        <thead>
          <tr>
            <th class="text-left">ID пользователя</th>
            <th v-if="!inCompetence" class="text-left">Компетенция</th>
            <th class="text-left">
              <sort-button
                :value="ordering"
                sort-field="year"
                @input="(v) => $emit('setOrdering', v)"
                >Год</sort-button
              >
            </th>
            <th>
              <sort-button
                :value="ordering"
                sort-field="id"
                @input="(v) => $emit('setOrdering', v)"
                >Создано</sort-button
              >
            </th>
            <th>Stepik</th>
            <th>Kaggle</th>
            <th>Git(Hub/Lab)</th>
            <th>StackOverflow</th>
            <th class="text-left">
              <sort-button
                :value="ordering"
                sort-field="score"
                @input="(v) => $emit('setOrdering', v)"
                >Итоговый балл</sort-button
              >
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="userScore in list" :key="userScore.id">
            <td>
              <a
                title="Открыть аккаунт с CRM"
                :href="`${crmURL}/contacts/${userScore.user_id}`"
                target="_blank"
                class="text-decoration-none"
                rel="noopener noreferrer"
              >
                {{ userScore.user_id }}
                <v-icon class="ml-1" small>mdi-open-in-new</v-icon></a
              >
            </td>
            <td v-if="!inCompetence">
              {{
                competences[userScore.competence_id]
                  ? competences[userScore.competence_id].name
                  : userScore.competence_id
              }}
            </td>
            <td>{{ userScore.year }}</td>
            <td>{{ userScore.created_at }}</td>
            <td>{{ userScore.details.stepik }}</td>
            <td>{{ userScore.details.kaggle }}</td>
            <td>{{ userScore.details.git_repo }}</td>
            <td>{{ userScore.details.stackoverflow }}</td>
            <td>
              {{ userScore.score || 0 }}
            </td>
            <td class="text-no-wrap">
              <v-hover v-slot="{ hover }">
                <v-btn
                  v-if="userScore.year === currentYear"
                  :color="hover ? 'primary' : 'primary lighten-1'"
                  title="Пересчитать баллы пользователя"
                  icon
                  :disabled="resyncIds[userScore.id]"
                  @click.prevent="syncScores(userScore)"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  :color="hover ? 'primary' : 'primary lighten-1'"
                  title="Детализация баллов"
                  icon
                  @click.prevent="openDetailsModal(userScore)"
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </v-hover>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <score-details-modal v-model="scoreModal" :score="selectedScore" />
  </div>
</template>

<script>
import SortButton from "@/components/SortButton";
import { apiClient } from "@/api";
import ScoreDetailsModal from "./ScoreDetailsModal";
export default {
  name: "ScoresList",
  components: {
    SortButton,
    ScoreDetailsModal,
  },
  props: {
    inCompetence: {
      type: Boolean,
      default: false,
    },
    ordering: {
      type: String,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      resyncIds: {},
      scoreModal: false,
      selectedScore: null,
    };
  },
  computed: {
    crmURL() {
      return process.env.VUE_APP_CRM_ORIGIN;
    },
    competences() {
      return this.$store.state.competences.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
      }, {});
    },
  },
  methods: {
    async syncScores(scoreData) {
      if (!scoreData?.user_id || !scoreData?.competence_id) return;
      try {
        const { data } = await apiClient({
          method: "POST",
          url: `/competences/${scoreData?.competence_id}/users_tasks`,
          data: {
            user_id: scoreData.user_id,
          },
        });
        if (data?.id) {
          this.$set(this.resyncIds, scoreData.id, true);
          this.$toast(
            `Задача #${data?.id} на перерасчет баллов для пользователя ${scoreData.user_id} создана.`,
            {
              type: "success",
            }
          );
        }
      } catch (error) {
        this.$toast(
          `Не удалось создать задачу на перерасчет баллов для пользователя ${scoreData.user_id}. ${error.message}`,
          {
            type: "error",
          }
        );
      }
    },
    openDetailsModal(score) {
      this.selectedScore = score;
      this.$nextTick(() => {
        this.scoreModal = true;
      });
    },
  },
};
</script>

<style></style>
