<template>
  <button
    :class="{ 'is-active': isActive }"
    class="sort-btn"
    :title="
      isAsc
        ? 'Сортировать по возрастанию'
        : isDesc
        ? 'Сортировать по убыванию'
        : `Сортировать по ${sortField}`
    "
    @click="handleClick"
  >
    <v-icon small left :color="isActive ? 'primary' : 'grey lighten-1'">{{
      isAsc ? "mdi-sort-ascending" : isDesc ? "mdi-sort-descending" : "mdi-sort"
    }}</v-icon
    >&nbsp;<span class="sort-btn__label">
      <slot>{{ sortField }}</slot>
    </span>
  </button>
</template>

<script>
export default {
  name: "SortButton",
  props: {
    sortField: String,
    value: String,
  },

  computed: {
    isActive() {
      const { isAsc, isDesc } = this;
      return isAsc || isDesc;
    },
    // Сортировка по возрастанию
    isAsc() {
      return this.value === `-${this.sortField}`;
    },
    // Сортировка по убыванию
    isDesc() {
      return this.value === this.sortField;
    },
  },
  methods: {
    handleClick() {
      const { isActive, isAsc, sortField } = this;
      if (!isActive) {
        this.$emit("input", `-${sortField}`);
      } else if (isAsc) {
        this.$emit("input", sortField);
      } else {
        this.$emit("input", `-${sortField}`);
      }
    },
  },
};
</script>

<style lang="scss">
.sort-btn {
  &__label {
    border-bottom: 1px dashed currentColor;
  }
}
</style>
