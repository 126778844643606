import { render, staticRenderFns } from "./ScoreDetailsModal.vue?vue&type=template&id=696a1f7e&scoped=true"
import script from "./ScoreDetailsModal.vue?vue&type=script&lang=js"
export * from "./ScoreDetailsModal.vue?vue&type=script&lang=js"
import style0 from "./ScoreDetailsModal.vue?vue&type=style&index=0&id=696a1f7e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "696a1f7e",
  null
  
)

export default component.exports