/**
 * Год начала сбора данных по конкурсу талант
 * (Используется в опциях для фильтрации баллов)
 */
export const INITIAL_YEAR = 2018;

/**
 * Возвращает массив годов для фильтрации
 * @returns {number[]}
 */
export const getScoreYears = () => {
  const currentYear = new Date().getFullYear();
  const result = [];
  for (let year = currentYear; year > INITIAL_YEAR; year--) {
    result.push(year);
  }
  return result;
};
